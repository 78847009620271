/*//the size of the page and color when we hover..*/
.Events-box {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    z-index: 15;
    background-color: rgb(0, 0, 0);
  }   
  
  .Event_Category
  {
    position: absolute;
    top: 100vh;
    width: 160vh;
    margin-left: 17vh;
    height: 30vh;
    margin-top: -20vh;
    margin-bottom: 25vh;
    z-index: 30;
  }
  
  .Event_category_tittle
  {
    position: relative;
    width: 90%;
    height: 20%;
   
    margin-bottom: 1%;
  
    /*font details*/
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size:5vh;
    font-weight: 600;
  }
  
  .Carousel_Placer
  {
    position: relative;
    width: 90%;
    height: 65%;
    
  }
  
  .slide
  {
    position: relative;
    height: 80%;
    width: 20%;
    margin-right: 1%;
    background-color: #ff0000;
    float: left;
  }
  
  .slide_img
  {
    height: 100%;
    width: 100%;
  }

.fullpage {
    background-color: rgba(45, 45, 45, 1);
    width: 70vh;
    height: 65vh;
    position: relative;
  }
/*the size of guset images*/
.guests{
    width: 7vh;
    height:7vh;
    padding: 0.5vh;
}
.divbottom{
    position: absolute;
    bottom: 0;
    padding: 1vh;
    margin: 1vh;/* images to appear in bottom postion relative and absolute is added then this is to align it */
}


.expand_down
{
    color: white;
}

.push_down
{
    color:white;
}

.slide .divbottom {
    display: none;
    position:relative;
    z-index: 25;
    top:-137%;
    left:-37%;
    background-color: rgb(35, 35, 35); /* Semi-transparent background */
    height:230%;
    width:150%;
    padding: 0px;
    border-radius: 3px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
    color: white; /* Text color */
    /* Add other styles as needed */
  }
  
  /* When the mouse hovers over the .slide element, show the .divbottom element */
  .slide:hover .divbottom {
    display: block;
  }

  .slide_video
{
  width: 100%;
}

.dropdown_button{
    cursor: pointer;
    background-color: #ffffff1e;
    position: relative;
    border-radius: 5vh;
    height: 5vh;
    width: 5vh;
    z-index: 20;
    margin-left: 85%;
    margin-top: 3%;
    border: #ffffff;
    border-color: #f0f8ff;
    border-style: solid;
    text-align: center;
    
}

.dropdown_button:hover{
    background-color: #ffffff5b;
}
.dropdown{
    margin: 0px;
    vertical-align: middle;
    width: 2.2vh;
    height: auto;
}

.guest_list{
    position:relative;
    
    margin-top: -10%;
    height:20%;
    width:100%;
    padding: 0px;
}

.guest
{
    
    float: left;
    margin-left: 2%;
    margin-top: .5%;
    border-radius: 8vh;
    height:5vh;
    width: 5vh;
    background-color: #ffffff;
    border: none;
    border-style: thick;


}

.slide_volume
{
    position: absolute;
    left:90%;
    top: 50%;
    height: 3vh;
    width: 3vh;
}


.event-text {
  font-size: 7vh;
  padding-left: 2%;
  font-weight: 600;
  padding-top: 0%;
  margin-bottom: 0;
}

.Events-box {
  padding-left: 2%;
  padding-right: 2%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: black;
  color: white;
}

/* MINIEVENTS SLIDER CSS */

:root {
  --handle-size-mini: 3rem;
  --img-gap: 1rem;  /* Change this to adjust carousel picture size */
  --items-per-screen-mini: 3;
  --slider-index: 0;
}

.carousel-container-mini {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 97.5%;
  /* margin-top: 5vh; */
}

.slider-mini {   
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(-0%);
}

.slider-mini > img {
  flex: 0 0 calc(100% / var(--items-per-screen-mini));
  max-width: calc(100% / var(--items-per-screen-mini));
  aspect-ratio: 1 / 1;
  padding: var(--img-gap);
  cursor: pointer;
  /* object-fit: cover; */
}

.slider-button-mini {
  border: none;
  background-color: rgba(20, 20, 20);
  z-index: 10;
  margin: var(--img-gap) 0;
  width: var(--handle-size-mini);
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* MINIEVENTS css ends */


/* MEGAEVENTS SLIDER CSS */

:root {
  --handle-size-mega: 3rem;
  --img-gap: 1rem;  /* Change this to adjust carousel picture size */
  --items-per-screen-mega: 3;
  --slider-index: 0;
}

.carousel-container-mega {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 97.5%;
}

.slider-mega {   
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(-0%);
}

.slider-mega > img {
  flex: 0 0 calc(100% / var(--items-per-screen-mega));
  max-width: calc(100% / var(--items-per-screen-mega));
  aspect-ratio: 1 / 1;
  padding: var(--img-gap);
  cursor: pointer;
  /* object-fit: cover; */
}

.slider-button-mega {
  border: none;
  background-color: rgba(20, 20, 20);
  z-index: 10;
  margin: var(--img-gap) 0;
  width: var(--handle-size-mega);
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* MegaEvents css ends */


/* WORKSHOPS SLIDER CSS */

:root {
  --handle-size-work: 3rem;
  --img-gap: 1rem;  /* Change this to adjust carousel picture size */
  --items-per-screen-work: 3;
  --slider-index: 0;
}

.carousel-container-work {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 97.5%;
}

.slider-work {
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(-0%);
}

.slider-work > img {
  flex: 0 0 calc(100% / var(--items-per-screen-work));
  max-width: calc(100% / var(--items-per-screen-work));
  aspect-ratio: 1 / 1;
  padding: var(--img-gap);
  cursor: pointer;
  /* object-fit: cover; */
}

.slider-button-work {
  border: none;
  background-color: rgba(20, 20, 20);
  z-index: 10;
  margin: var(--img-gap) 0;
  width: var(--handle-size-work);
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* WORKSHOPS css ends */

/* 6DT css starts */

:root {
  --handle-size-six: 3rem;
  --img-gap: 1rem;  /* Change this to adjust carousel picture size */
  --items-per-screen-six: 3;
  --slider-index: 0;
}

.carousel-container-six {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 97.5%;
}

.slider-six {
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(-0%);
}

.slider-six > img {
  flex: 0 0 calc(100% / var(--items-per-screen-six));
  max-width: calc(100% / var(--items-per-screen-six));
  aspect-ratio: 1 / 1;
  padding: var(--img-gap);
  cursor: pointer;
  /* object-fit: cover; */
}

.slider-button-six {
  border: none;
  background-color: rgba(20, 20, 20);
  z-index: 10;
  margin: var(--img-gap) 0;
  width: var(--handle-size-six);
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* 6DT css ends */

/* sponsors css starts */

:root {
  --handle-size-spon: 3rem;
  --img-gap: 1rem;  /* Change this to adjust carousel picture size */
  --items-per-screen-spon: 5;
  --slider-index: 0;
}

.carousel-container-spon {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 97.5%;
}

.slider-spon {
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(-0%);
}

.slider-spon > img {
  flex: 0 0 calc(100% / var(--items-per-screen-spon));
  max-width: calc(100% / var(--items-per-screen-spon));
  max-height: calc(100% / vw);
  aspect-ratio: 1 / 1;
  padding: var(--img-gap);
  cursor: pointer;
  /* object-fit: cover; */
}

.slider-button-spon {
  border: none;
  background-color: rgba(20, 20, 20);
  z-index: 10;
  margin: var(--img-gap) 0;
  width: var(--handle-size-spon);
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* sponsors css ends */

.left-handle {
  max-width: 5rem;
}

.right-handle {
  max-width: 5rem;
}

#footer {
  background-color:   black;
  color: #fff;
  text-align: center;
  padding: 20px;
  bottom: 0;
  width: 100%;
}

#copyright {
  font-size: 12px;
}

#goToTop {
  border: solid 2px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
}

#foot-text{
  margin-bottom: 20px;
}

#goToTop:hover {
    background-color: grey;
}