*
{
  border:none;
}



/* Actual body doesnt have a horizontal scroll*/

/* WebKit-based browsers (e.g., Chrome, Safari) */
/* Customize the track */
::-webkit-scrollbar {
  width: 12px;
  background-color: #000000;
}

/* Customize the thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #333; /* Color of the thumb */
  border-radius: 6px; /* Round the corners of the thumb */
}

/* Customize the track when the scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover color */
}

body
{
  overflow-x: hidden; 
  background-color: #000000;
}

/*div container*/
.body
{
  font-family: Arial, sans-serif;
  height: 100vh;
}
/*Menu bar*/
.navbar {

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 20;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  padding: 1.5vh;
}

.navbar_colour
{
  background-color: #1c1c1c;
}

.navbar a {
  float: left;
  font-size: 2.5vh;
  color: rgb(202, 202, 202);
  text-align: center;
  padding: 1px 16px;
  text-decoration: none;
  
}

.navbar a:hover {
  color: rgb(255, 255, 255);
}

.logo {
  height: 3.5vw;  
  
}




.container {

  position: relative;
  width: 100vw;
  height: auto;
  padding: 0;
}    

.Events_container
{
  position: absolute;
  top: 78vh;
  left: 5vh;
  z-index: 15;
}

.icn_size
{
  height:3.5vh;
  width: auto;
  margin-right: 3vh;
}

.hello{
  position: absolute;
  top: 69vh;
  left: 17vh;
  z-index: 15;  
}










.img_icn
{
  position: absolute;
  padding: .75vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  
}

.volume_button{
  cursor: pointer;
  background-color: #ffffff08;
  position: absolute;
  border-radius: 5vh;
  height: 7vh;
  width: 7vh;
  z-index: 15;
  right: 5vw;
  top:68vh;
  border: #ffffff;
  border-color: aliceblue;
  border-style: double;
}

.volume_button:hover{
  background-color: #ffffff5b;
}
.volume{
  
  width: 65%;
  height: auto;
}

.space{
margin-top: 2.1vh;
}

.space2{
margin-bottom: 10px;
}

.imgg{
  height: auto;
  width:80vh;
  position: absolute;
  margin: 0;
  padding: 0;
  left:-6.5vh;
  top:20vh;
  z-index: 15;
}

.glow-button {

  position: relative;
  background-color: #ffffff;
  color: #000000;
  border: none;
  
  height: 7vh;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 20px;
  padding: .5vh;
  font-size: 2.5vh;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  width:26vh;
}
  
  .glow-button:hover {
    background-color: #ffffff9e;
    
  }

  .glow-button2:hover {

    background-color: #191919cf;
  }
  .glow-button2 {

  position: relative;
  background-color: #2525258d;
  color: #ffffff;
  border: none;
  width:22vh;
  height: 8vh;;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 20px;
  padding: 5px;
  font-size: 2.5vh;
  
  }
.Investiture1
{
  z-index : 5;
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
  border: none;
  
}

.button_text
{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 1vh;
  width: 100%;
  height: 50%;
  font-size: 3vh;
  
}


/*The info regarding the events*/
.trans-slideshow {

  position: absolute;
  bottom:0;
  width: 100vw;
  height: 50vw;
  
  z-index: 10;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  

}


@media (orientation: portrait) {
  .body
  {
    display: none;
  }

  .message_to_invert_screen
  {
    display:block;
  
  }
}

.popup {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
  /* color: black; */
  background-color: black;
}

.content
{
  position: relative;
  top: -.75vh;
}
.popup-content {
  /* background: #fff; */
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.rt {
  width: 10%;
  height: 10%;
  position: relative;
  color: black;
  animation: rotate-icon 5s linear infinite;
}

@keyframes rotate-icon {
  0% {
    transform: rotateZ(-45deg);   
  }
  25% {
    transform: rotateZ(45deg);   
  }
  50% {
    transform: rotateZ(45deg);   
  }
  75% {
    transform: rotateZ(-45deg);   
  }
  100% {
    transform: rotateZ(-45deg);   
  }
}

@media (orientation: portrait) {
  .popup {
    display: flex;
  }
}

.block
{
  display: none;
  z-index: -1000;
}

