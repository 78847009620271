
.blur .img_icn
{
  position: absolute;
  padding: .75vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  
}
.blur
{
  z-index: 10000;
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
  background: rgba(0, 0, 0, 0.169); /* Adjust the background color and opacity as needed */
  font-family: Netflix Sans,Helvetica Neue,Segoe UI,Roboto,Ubuntu,sans-serif;
}

.blur .box
{
  display: block;
  position: fixed;
  top: 14vh;
  left: 20vw;
  width: 60vw;
  height: 82vh;
  background-color: rgb(0, 0, 0);
  border-radius: 8px;
  overflow-y: auto;
  
}

.blur .content
{
  height: auto;
  width: 100%;
  background-color: #000000;
  padding-bottom: 2vh;
}

.box::-webkit-scrollbar {
  width: 8px; /* Set the desired scrollbar width */
}

.box::-webkit-scrollbar-thumb {
  background: #333; /* Scrollbar thumb color (matches background) */
  border-radius: 8px; /* Rounded thumb */
}

.box::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover state color for the thumb */
}

/* Style for the close button container */
.blur .close {
  width: 4vh; /* Adjust the size as needed */
  height: 4vh;
  cursor: pointer;
  position: absolute;
  right:2px;
  top: 15px;
  background-color: #000000bf;
  border-radius: 100%;
  color: white;
  font-weight: 5; /* Adjust the font style as needed */
  cursor: pointer;
  z-index: 10;
}

.blur .close p {
  font-family: Arial, sans-serif;
  margin-top: 5px;
  text-align: center; /* Horizontal centering within the text */
}

.blur .video-container {
  max-width: 100%; /* Ensure the video does not exceed the container's width */
  position: relative;
  top: -7vh;
  height: auto;
  margin-bottom: -7%;
  
  
}

.blur .video {
  width: 100%;
  height: 30em;
  display: block;
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
  overflow: hidden;
}

.blur .img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
  overflow: hidden;
}

.blur .volume_button{
  cursor: pointer;
  background-color: #ffffff08;
  position: relative;
  border-radius: 5vh;
  height: 7vh;
  width: 7vh;
  z-index: 20;
  right: -55vw;
  top:-21vh;
  border: #ffffff;
  border-color: aliceblue;
  border-style: double;
}

.blur .contact-us {
  background-color: black;
  margin-left: 0%;
  color: white;
  
  font-size: 3vh;
  
  
}


.blur .contact-img {
  width: 3.6%;
  margin-left: 10%;
  margin-bottom: 2%;
}

.blur .contact-info .about-event {
  margin-left: 0%;
  padding-bottom: 3vh;
  
}

.blur .contact-one{
  background-color: black;
  margin-left: 0%;
  margin-top: -2%;
  color: white;
  
  
  margin-top: -5%;
  font-size: 3vh;
  
}


.blur .contact-two{
  background-color: black;
  margin-left: 15%;
  margin-top: -6%;
  margin-bottom: 100px;
  color: white;
  font-weight: normal;
  font-size: 3vh;
}

.blur .contact_info {
  margin-top: -11%;
}


.blur .contact-info {
  
  border: solid red;
  height: fit-content;
  width: 100%;
  padding: 5%;
  background-color: #ffffff;
  margin-top: 10%;
}

.blur .volume_button:hover{
  background-color: #ffffff5b;
}
.blur .volume{
  
  width: 65%;
  height: auto;
}
.blur .Translate
{
  position: absolute;
  bottom: 0vh;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  
}

.blur .glow-button {

  background-color: #ffffff;
  color: #000000;
  z-index: 300;
  border: none;
  width:23vh;
  height: 5vh;;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  margin-left: 5vh ;
  margin-right: 3vh;
  font-size: 2.5vh;
  font-weight: 700;
  position: absolute;
  bottom: 12vh;
  }
  
  .blur .glow-button:hover {
    background-color: #ffffff9e;
    
  }
  .blur .glow-button2 {
  position: absolute;
  bottom: 7vh;
  left: 16vw;
  background-color: #252525bb;
  color: #ffffff;
  border: none;
  width:20vh;
  height: 5vh;;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 20px;
  padding: 5px;
  font-size: 2.5vh;
  
  }

  .blur .button_text
  {
    position: relative;
    top: 10%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding-left: 1vh;
  }
  
  .blur .icn_size
{
  
  height:2vh;
  width: auto;
  background-color: white;
}
.blur .img_icn
{
  position: relative;
  top: 2.69vh;
  left: 2.3vh;
  width: 40px;
}

  .blur .glow-button2:hover {
  
    background-color: #0c0c0cc4;
    
  }

.blur .EventName
{
  font-size: 4vh;
}
.blur .About_info
{
  width: 60%; /* Set the desired width for the container */
  position: relative;
  text-align:justify;
  top: 0vh;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  float:left;
  
  padding-bottom: 10vh;
  color: #ffffff;
 
  
  
}

.blur .Details
{
  
  width: 25%; /* Set the desired width for the container */
  margin-left: 1.5%;
  float:left;
  padding-bottom: 10vh;
  color: #ffffff;

}

.blur .Event_tittle
{
  position: absolute;
  top:40%;
  left:-4vw;
  width:auto;
  height: 30%;
}

.contact_box
{
  width:50%;
  float:left;
  height: 16vh;
  margin-top: -5%;
  left: 1%;
}

.contact_box .contact-one {
  
}


.contact_info
{
  width: 90%; /* Set the desired width for the container */
  
  height: fit-content;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: -10%;
  float:left;
  height: fit-content;
  padding-bottom: 10vh;
  color: #ffffff;
  
}

.Details_span
{
  color: #747474;
  font-family: Netflix Sans,Helvetica Neue,Segoe UI,Roboto,Ubuntu,sans-serif;
}